import {
  SafeAreaView,
  View,
  Text,
  Platform,
  ScrollView,
  TextComponent,
  Dimensions,
  TouchableOpacity,
  Linking,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  makePlayer,
  nominatePlayer,
  votePlayer,
} from "../components/firebase/firebaseHooks";
import { assets, STButton, STInput, STStyles } from "../style";
import { Context as GameContext } from "../components/firebase/dataContext/GameContext";

const { width } = Dimensions.get("window");

const JoinGameView = ({ setPlayerCode, gameCode }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const makeThisPlayer = async () => {
    setLoading(true);
    const newPlayerCode = await makePlayer({ firstName, lastName, gameCode });
    Linking.openURL("/play/player/" + gameCode + "/" + newPlayerCode);
    setLoading(false);
  };
  console.log("Join game view");
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginVertical: 8,
        }}
      >
        <STInput
          placeholder="First Name"
          onChangeText={setFirstName}
          value={firstName}
        />
        <STInput
          placeholder="Last Name"
          onChangeText={setLastName}
          value={lastName}
        />
      </View>
      <STButton
        onPress={() => makeThisPlayer()}
        disabled={firstName == "" || lastName == ""}
      >
        <Text style={STStyles.button}>Join Game</Text>
      </STButton>
    </View>
  );
};

const PlayersView = ({
  allPlayers,
  players,
  round,
  ourPlayer,
  small,
  disabled,
  onPress,
  gameCode,
  showNominated,
  showVotes,
}) => {
  const size = width / (small ? 8 : 5);
  if (round == "vote") {
    players = players.filter((player) => player.nominated);
  }
  return (
    <View style={{ alignItems: "center" }}>
      <ScrollView style={{ marginVertical: 16 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {players.map((player, index) => {
            let nominator = null;
            if (allPlayers) {
              nominator = allPlayers.find(
                (player2) => player2.id == player.nominated
              );
            }
            return (
              <View style={{ alignItems: "center", margin: 4 }}>
                <TouchableOpacity
                  key={index.toString()}
                  style={{
                    width: size,
                    height: size,
                    backgroundColor: assets.SLATE_COLOR,
                    borderRadius: 8,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={disabled}
                  onPress={() => {
                    onPress({
                      nominatedCode: player.id,
                      ourCode: ourPlayer.id,
                      gameCode,
                    });
                  }}
                >
                  <Text style={STStyles.h4}>
                    {player.firstName} {player.lastName}
                  </Text>
                </TouchableOpacity>
                {showNominated ? (
                  <Text style={STStyles.p}>
                    Nominated By: {nominator.firstName} {nominator.lastName}
                  </Text>
                ) : null}
                {showVotes ? (
                  <View style={{ marginVertical: 4 }}>
                    <Text style={STStyles.h4}>Votes:</Text>
                    {allPlayers
                      .filter((thisPlayer) => player.id == thisPlayer.votedFor)
                      .map((votedPlayer, index) => {
                        return (
                          <View>
                            <Text style={STStyles.h4}>
                              {votedPlayer.firstName} {votedPlayer.lastName}
                            </Text>
                          </View>
                        );
                      })}
                  </View>
                ) : null}
              </View>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const PlayScreen = ({ navigation, firebase }) => {
  let gameCode = navigation.getParam("gameCode");
  let playerCodeParam = navigation.getParam("playerCodeParam");

  const {
    state: { players, round },
    watchGame,
  } = useContext(GameContext);

  useEffect(() => {
    if (gameCode) {
      watchGame(gameCode);
    }
  }, []);

  const [playerCode, setPlayerCode] = useState(
    playerCodeParam ? playerCodeParam : ""
  );
  const ourPlayer = players.find((thisPlayer) => thisPlayer.id == playerCode);
  const votedFor = ourPlayer
    ? players.find((thisPlayer) => thisPlayer.id == ourPlayer.votedFor)
    : null;
  const deadPlayers = players.filter((player) => player.dead);
  return (
    <View
      flex={1}
      style={{ backgroundColor: assets.DARK_COLOR, height: "100%" }}
    >
      {gameCode ? (
        <View style={{ margin: 16 }}>
          {playerCode == "" && round != "" ? (
            <View>
              <Text style={STStyles.h3}>This Game has already started.</Text>
            </View>
          ) : playerCode == "" ? (
            <JoinGameView setPlayerCode={setPlayerCode} gameCode={gameCode} />
          ) : round == "" || (ourPlayer && ourPlayer.dead) ? (
            <View>
              <Text
                style={[
                  STStyles.h1,
                  {
                    textAlign: "center",
                    color: assets.RED_COLOR,
                    marginVertical: 16,
                  },
                ]}
              >
                {round == ""
                  ? "Wait for the game to begin"
                  : "Oh dear, you are dead!"}
              </Text>
              <Text style={STStyles.h4}>
                {round == "" ? "Players:" : "Remaining Players:"}{" "}
              </Text>
              <PlayersView
                players={players.filter((player) => !player.dead)}
                small
                disabled
              />
              {round != "" ? (
                <View>
                  <Text style={STStyles.h4}>Ghosts (RIP): </Text>
                  <PlayersView
                    players={players.filter((player) => player.dead)}
                    small
                    disabled
                  />
                </View>
              ) : null}
            </View>
          ) : (
            <View>
              <View style={{ marginVertical: 16 }}>
                <Text
                  style={[
                    STStyles.h1,
                    {
                      textAlign: "center",
                      color: assets.RED_COLOR,
                    },
                  ]}
                >
                  {round == "sleep"
                    ? "Go to sleep!"
                    : round == "nominate"
                    ? "Nominate if you think someone is mafia"
                    : "Vote for who you think is mafia"}
                </Text>
                <Text
                  style={[
                    STStyles.p,
                    {
                      textAlign: "center",
                    },
                  ]}
                >
                  ({ourPlayer.role})
                </Text>
              </View>
              {round == "sleep" ? (
                <PlayersView
                  players={players.filter((player) => !player.dead)}
                  disabled
                />
              ) : null}
              {round == "nominate" ? (
                <View>
                  <PlayersView
                    players={players.filter(
                      (player) =>
                        !player.dead &&
                        !player.nominated &&
                        player.id != playerCode
                    )}
                    round={round}
                    allPlayers={players}
                    onPress={nominatePlayer}
                    ourPlayer={ourPlayer}
                    gameCode={gameCode}
                    disabled={
                      players.find(
                        (thisPlayer) => thisPlayer.nominated == playerCode
                      )
                        ? true
                        : false
                    }
                  />
                  <Text style={STStyles.h3}>Players Nominated: </Text>
                  <PlayersView
                    players={players.filter(
                      (player) => !player.dead && player.nominated
                    )}
                    round={round}
                    allPlayers={players}
                    showNominated
                    disabled
                  />
                </View>
              ) : null}
              {round == "vote" ? (
                <View>
                  <Text style={STStyles.h3}>Players Nominated: </Text>
                  <PlayersView
                    players={players.filter(
                      (player) => !player.dead && player.nominated
                    )}
                    round={round}
                    allPlayers={players}
                    showNominated
                    disabled={votedFor ? true : false}
                    onPress={votePlayer}
                    ourPlayer={ourPlayer}
                    gameCode={gameCode}
                    showVotes
                  />
                  {votedFor ? (
                    <View>
                      <Text
                        style={[
                          STStyles.h3,
                          { color: assets.RED_COLOR, textAlign: "center" },
                        ]}
                      >
                        Voted for: {votedFor.firstName} {votedFor.lastName}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null}
              {deadPlayers.length > 0 ? (
                <View style={{ marginVertical: 64 }}>
                  <Text style={STStyles.h4}>Ghosts (RIP): </Text>
                  <PlayersView players={deadPlayers} small disabled />
                </View>
              ) : null}
            </View>
          )}
        </View>
      ) : (
        <Text style={STStyles.h4}>Something is wrong</Text>
      )}
    </View>
  );
};

export default PlayScreen;
