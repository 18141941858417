import React, { useContext, useEffect, useState } from "react";
import { Linking, Text, View } from "react-native";
import { withFirebaseHOC } from "./components/firebase";
import { assets, STButton, STStyles, STInput } from "./style";
import { makeGame, joinGame } from "./components/firebase/firebaseHooks";
import { Header } from "./components/Header";

const InitialScreen = ({ navigation, firebase }) => {
  const [loading, setLoading] = useState(false);

  const makeThisGame = async () => {
    setLoading(true);
    let gameCode = "";
    gameCode = await makeGame();
    Linking.openURL("/play/godmode/" + gameCode);
    //navigation.navigate("God", { gameCode });
    setLoading(false);
  };

  const [gameCode, setGameCode] = useState("");
  const findThisGame = async () => {
    setLoading(true);
    const exists = await joinGame({ gameCode });
    if (exists) {
      Linking.openURL("/play/player/" + gameCode);
      //navigation.navigate("Play", { gameCode });
    } else {
      console.log("No code");
    }
    setLoading(false);
  };

  return (
    <View
      flex={1}
      style={{
        backgroundColor: assets.DARK_COLOR,
        height: "100%",
        alignItems: "center",
      }}
    >
      <View>
        <Text style={[STStyles.title]}>Mafia</Text>
        <View style={{ margin: 16 }}>
          <STButton
            disabled={loading}
            onPress={() => {
              makeThisGame();
            }}
          >
            <Text style={STStyles.button}>
              {loading ? "Loading" : "Start Game"}
            </Text>
          </STButton>
        </View>
        <View style={{ margin: 16 }}>
          <STInput
            placeholder="Game Code"
            onChangeText={setGameCode}
            value={gameCode}
          />
          =
          <STButton
            disabled={gameCode == "" || loading}
            style={{ marginVertical: 8 }}
            onPress={() => findThisGame()}
          >
            <Text style={STStyles.button}>Join Game</Text>
          </STButton>
          =
        </View>
      </View>
    </View>
  );
};

export default withFirebaseHOC(InitialScreen);
