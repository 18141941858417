import firebase from "firebase/app";
import "firebase/firestore";

import createDataContext from "./createDataContext";

const gameReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    //Modifying roster array logic
    case "add_player":
      let found = false;
      newState.players = state.players.map((player) => {
        if (player.id === action.payload.data.id) {
          found = true;
          if (player.url && !action.payload.data.url) {
            action.payload.data.url = player.url;
          }
          return action.payload.data;
        }
        return player;
      });
      if (!found) {
        newState.players.push(action.payload.data);
      }
      return newState;
    case "remove_player":
      newState.players = newState.players.filter(
        (player) => player.id !== action.payload.data.id
      );
      return newState;
    case "remove_all_players":
      return { players: [], round: "" };
    case "set_round":
      newState.round = action.payload.round;
      return newState;
    default:
      return state;
  }
};

//getImageURL
const getImageUrl = ({ dispatch, tid, data }) => {
  storage()
    .ref(`playerImgs/${tid}/${data.id}`)
    .getDownloadURL()
    .then((url) => {
      newData = { ...data, url };
      dispatch({ type: "modify_player", payload: { data: newData } });
    });
};

//Listens to players
let playerSubscriber = null;
const addPlayerSubscriber = ({ dispatch, code }) => {
  playerSubscriber = firebase
    .firestore()
    .collection(`games/${code}/players`)
    .onSnapshot(
      (snap) => {
        snap.docChanges().forEach((change) => {
          //Doc ID is the player's id
          const id = change.doc.id;
          let data = change.doc.data();
          data.id = id;
          if (change.type === "added" || change.type === "modified") {
            dispatch({ type: "add_player", payload: { data } });
          }
          if (change.type === "removed") {
            dispatch({ type: "remove_player", payload: { id } });
          }
          if (data.imgUpdated != null) {
            getImageUrl({ dispatch, tid, data });
          }
        });
      },
      (err) => {
        console.log("Error in addPlayerSub");
        dataError(JSON.stringify(err));
      }
    );
};

//Listens to game
let gameSubscriber = null;
const addGameSubscriber = ({ dispatch, code }) => {
  gameSubscriber = firebase
    .firestore()
    .doc(`games/${code}`)
    .onSnapshot(
      (doc) => {
        const data = doc.data();
        if (data.round) {
          dispatch({ type: "set_round", payload: { round: data.round } });
        }
      },
      (err) => {
        console.log("Error in addPlayerSub");
        dataError(JSON.stringify(err));
      }
    );
};

//Called when a new team is switched to.  Starts up the listeners
//for every roster the team has
const watchGame = (dispatch) => {
  return (code) => {
    console.log("Adding game listener w code: ", code);
    //Remove any old rosters left from switching teams
    dispatch({ type: "remove_all_players" });

    //Add player Subscriber
    if (playerSubscriber != null) {
      //Unsubscribe from old listener if there is one
      playerSubscriber();
    }
    addPlayerSubscriber({ dispatch, code });

    //Add game Subscriber
    if (gameSubscriber != null) {
      //Unsubscribe from old listener if there is one
      gameSubscriber();
    }
    addGameSubscriber({ dispatch, code });
  };
};

//removeRosterListener
//Called when logging out to remove listeners completely
const removeGameListener = (dispatch) => {
  return () => {
    playerSubscriber == null ? null : rosterSubscriber();
    gameSubscriber == null ? null : rosterSubscriber();
    dispatch({ type: "remove_all_players" });
  };
};

export const { Context, Provider } = createDataContext(
  gameReducer,
  { removeGameListener, watchGame },
  { players: [], round: "" }
);
