import firebase from "firebase";
import "firebase/firestore";

const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

const makeid = async (length) => {
  let exists = true;
  var result = "";
  while (exists) {
    var characters = "ABCDEFGHIJKLMNPRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log("Looking");
    await firebase
      .firestore()
      .doc("games/" + result)
      .get()
      .then((doc) => {
        if (doc.data()) {
          console.log("Already Exists " + result);
        } else {
          console.log("Doesn't Exist " + result);
          exists = false;
        }
      });
  }
  return result;
};

export const joinGame = async ({ gameCode }) => {
  let exists = false;
  await firebase
    .firestore()
    .doc("games/" + gameCode)
    .get()
    .then((doc) => {
      if (doc.data()) {
        console.log("Already Exists ");
        exists = true;
      } else {
        console.log("Doesn't Exist ");
      }
    });
  return exists;
};

export const makeGame = async () => {
  const gameCode = await makeid(3);
  await firebase
    .firestore()
    .doc("games/" + gameCode)
    .set({ gameCode });
  return gameCode;
};

const makeplayerid = async (length, gameCode) => {
  let exists = true;
  var result = "";
  while (exists) {
    var characters = "ABCDEFGHIJKLMNPRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    await firebase
      .firestore()
      .doc("games/" + gameCode + "/players/" + result)
      .get()
      .then((doc) => {
        if (doc.data()) {
          console.log("Already Exists " + result);
        } else {
          console.log("Doesn't Exist " + result);
          exists = false;
        }
      });
  }
  return result;
};

export const makePlayer = async ({ firstName, lastName, gameCode }) => {
  const playerCode = await makeplayerid(5, gameCode);
  console.log("Made: " + playerCode);
  await firebase
    .firestore()
    .doc("games/" + gameCode + "/players/" + playerCode)
    .set({ firstName, lastName });
  return playerCode;
};

export const setRound = async ({ gameCode, round }) => {
  await firebase
    .firestore()
    .doc("games/" + gameCode)
    .update({ round });
};

export const nominatePlayer = ({ nominatedCode, ourCode, gameCode }) => {
  firebase
    .firestore()
    .doc("games/" + gameCode + "/players/" + nominatedCode)
    .update({ nominated: ourCode });
};

export const votePlayer = ({ nominatedCode, ourCode, gameCode }) => {
  firebase
    .firestore()
    .doc("games/" + gameCode + "/players/" + ourCode)
    .update({ votedFor: nominatedCode });
};

export const removeAllNominations = ({ players, gameCode }) => {
  for (const player of players) {
    firebase
      .firestore()
      .doc("games/" + gameCode + "/players/" + player.id)
      .update({ nominated: firebase.firestore.FieldValue.delete() });
  }
};

export const removeAllVotes = ({ players, gameCode }) => {
  for (const player of players) {
    firebase
      .firestore()
      .doc("games/" + gameCode + "/players/" + player.id)
      .update({ votedFor: firebase.firestore.FieldValue.delete() });
  }
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const assignRoles = ({ players, gameCode }) => {
  let mafiaCount = Math.floor(players.length / 3);
  let doctorCount = 1;
  let sheriffCount = 1;
  const newPlayers = shuffle(players);
  for (const player of newPlayers) {
    let role = "Citizen";
    if (mafiaCount > 0) {
      role = "Mafia";
      mafiaCount -= 1;
    } else if (sheriffCount > 0) {
      role = "Sheriff";
      sheriffCount -= 1;
    } else if (doctorCount > 0) {
      role = "Doctor";
      doctorCount -= 1;
    }
    firebase
      .firestore()
      .doc("games/" + gameCode + "/players/" + player.id)
      .update({ role });
  }
};

export const setDead = ({ playerID, gameCode, dead }) => {
  firebase
    .firestore()
    .doc("games/" + gameCode + "/players/" + playerID)
    .update({ dead });
};
