import {
  SafeAreaView,
  View,
  Text,
  Platform,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  setRound,
  removeAllNominations,
  removeAllVotes,
  setDead,
  assignRoles,
} from "../components/firebase/firebaseHooks";
import { assets, STButton, STStyles } from "../style";
import { Context as GameContext } from "../components/firebase/dataContext/GameContext";

const { width } = Dimensions.get("window");

const PlayersView = ({ players, gameCode, showVotes, allPlayers }) => {
  const size = width / 5;
  return (
    <View style={{ alignItems: "center" }}>
      <ScrollView style={{ marginVertical: 16 }}>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {players.map((player, index) => {
            console.log(player);
            return (
              <View>
                <TouchableOpacity
                  key={index.toString()}
                  style={{
                    width: size,
                    height: size,
                    backgroundColor:
                      player.role == "Mafia"
                        ? assets.RED_COLOR
                        : assets.SLATE_COLOR,
                    margin: 4,
                    borderRadius: 8,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() =>
                    setDead({
                      gameCode,
                      playerID: player.id,
                      dead: player.dead ? false : true,
                    })
                  }
                >
                  <Text style={STStyles.h4}>
                    {player.firstName} {player.lastName}
                  </Text>
                  {player.role == "Doctor" || player.role == "Sheriff" ? (
                    <View
                      style={{
                        backgroundColor: assets.DARK_COLOR,
                        borderRadius: size / 8,
                        borderWidth: 1,
                        borderColor: assets.RED_COLOR,
                        padding: 4,
                        width: size / 4,
                        height: size / 4,
                        position: "absolute",
                        top: -4,
                        left: -4,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={[STStyles.h3, { color: assets.RED_COLOR }]}>
                        {player.role[0]}
                      </Text>
                    </View>
                  ) : null}
                </TouchableOpacity>
                {showVotes ? (
                  <View style={{ marginVertical: 4 }}>
                    <Text style={STStyles.h4}>Votes:</Text>
                    {allPlayers
                      .filter((thisPlayer) => player.id == thisPlayer.votedFor)
                      .map((votedPlayer, index) => {
                        return (
                          <View>
                            <Text style={STStyles.h4}>
                              {votedPlayer.firstName} {votedPlayer.lastName}
                            </Text>
                          </View>
                        );
                      })}
                  </View>
                ) : null}
              </View>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const GodScreen = ({ navigation, firebase }) => {
  const gameCode = navigation.getParam("gameCode");

  const {
    state: { players, round },
    watchGame,
  } = useContext(GameContext);

  useEffect(() => {
    watchGame(gameCode);
  }, []);

  return (
    <View
      flex={1}
      style={{
        backgroundColor: assets.DARK_COLOR,
        height: "100%",
        paddingHorizontal: 16,
      }}
    >
      <Text style={[STStyles.title]}>Mafia</Text>
      <Text style={[STStyles.h2, { textAlign: "center" }]}>God Mode</Text>
      <Text style={[STStyles.h4, { textAlign: "center" }]}>
        Game Code: {gameCode}
      </Text>
      <View style={{ marginVertical: 32 }}>
        <Text style={STStyles.h3}>Remaining Players:</Text>
        <PlayersView
          players={players.filter(
            (player) => !player.dead && (round != "vote" || player.nominated)
          )}
          round={round}
          gameCode={gameCode}
          showVotes={round == "vote" ? true : null}
          allPlayers={players}
        />
      </View>
      <View style={round == "" ? { display: "none" } : { marginVertical: 32 }}>
        <Text style={STStyles.h3}>Dead Players:</Text>
        <PlayersView
          players={players.filter((player) => player.dead)}
          round={round}
          gameCode={gameCode}
        />
      </View>
      <View style={{ marginVertical: 32 }}>
        <Text style={STStyles.h3}>
          {round == "" ? "" : "Current Round: " + round}
        </Text>
        {round == "" ? (
          <STButton
            onPress={() => {
              assignRoles({ gameCode, players });
              setRound({ gameCode, round: "sleep" });
            }}
          >
            <Text style={STStyles.button}>Start Game</Text>
          </STButton>
        ) : (
          <View
            style={{
              flexDirection: "row",
              overflow: "scroll",
              paddingVertical: 8,
            }}
          >
            <STButton
              onPress={() => setRound({ gameCode, round: "sleep" })}
              style={{ marginHorizontal: 8 }}
            >
              <Text style={STStyles.button}>sleep</Text>
            </STButton>
            <STButton
              onPress={() => {
                removeAllNominations({ gameCode, players });
                setRound({ gameCode, round: "nominate" });
              }}
              style={{ marginHorizontal: 8 }}
            >
              <Text style={STStyles.button}>nominate</Text>
            </STButton>
            <STButton
              onPress={() => {
                removeAllVotes({ gameCode, players });
                setRound({ gameCode, round: "vote" });
              }}
              style={{ marginHorizontal: 8 }}
            >
              <Text style={STStyles.button}>vote</Text>
            </STButton>
          </View>
        )}
      </View>
    </View>
  );
};

export default GodScreen;
