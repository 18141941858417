import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import CachedImage from "react-native-expo-cached-image";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";

const assets = {
  //FONTS
  FONT_SIZE_TITLE: 58,
  FONT_SIZE_H1: 34,
  FONT_SIZE_H2: 26,
  FONT_SIZE_H3: 22,
  FONT_SIZE_H4: 16,
  FONT_SIZE_P: 14,
  FONT_SIZE_BUTTON: 18,
  FONT_SIZE_ICON: 28,
  FONT_WEIGHT_HEAVY: "900",
  FONT_WEIGHT_BOLD: "700",
  FONT_WEIGHT_LIGHT: "200",
  //COLORS
  DARK_COLOR: "#04080f",
  RED_COLOR: "#931621",
  SLATE_COLOR: "#28464B",
  BLUE_COLOR: "#2C8C99",
  //OTHER
  PROFILE_IMG_HEIGHT: 56,
};

const STButton = ({
  children,
  borderRadius,
  onPress,
  color,
  disabled,
  filled,
  style,
  alertAmount,
}) => {
  return (
    <View
      style={[
        {
          borderRadius: borderRadius ? borderRadius : 999,
        },
        STStyles.shadow,
        style,
      ]}
    >
      <TouchableOpacity
        style={{
          borderRadius: borderRadius ? borderRadius : 999,
          overflow: "hidden",
        }}
        disabled={disabled}
        onPress={() => onPress()}
      >
        {color ? (
          <View
            style={{
              borderRadius: borderRadius ? borderRadius - 2 : 999,
              backgroundColor: "rgba(23,25,36,1)",
              paddingVertical: 4,
              paddingHorizontal: 12,
              alignItems: "center",
              borderWidth: 2,
              borderColor: color,
            }}
          >
            {children}
          </View>
        ) : (
          <LinearGradient
            style={{ padding: 2 }}
            colors={[assets.SLATE_COLOR, assets.BLUE_COLOR]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
          >
            <View
              style={{
                borderRadius: borderRadius ? borderRadius - 2 : 999,
                backgroundColor: filled ? null : "rgba(23,25,36,1)",
                paddingVertical: 4,
                paddingHorizontal: 12,
                alignItems: "center",
              }}
            >
              {children}
            </View>
          </LinearGradient>
        )}
      </TouchableOpacity>
      {alertAmount ? (
        <View
          style={{
            position: "absolute",
            top: -6,
            right: -6,
            width: 20,
            height: 20,
            backgroundColor: assets.RED_COLOR,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={STStyles.p}>{alertAmount}</Text>
        </View>
      ) : null}
    </View>
  );
};

const STButtonContent = ({
  children,
  borderRadius,
  style,
  onPress,
  disabled,
}) => {
  return (
    <View
      style={[
        {
          borderRadius: borderRadius ? borderRadius : 999,
          alignSelf: "center",
          width: "100%",
        },
        style,
        STStyles.shadow,
      ]}
    >
      <TouchableOpacity
        style={{
          borderRadius: borderRadius ? borderRadius : 999,
          overflow: "hidden",
        }}
        onPress={() => onPress()}
        disabled={disabled}
      >
        <LinearGradient
          style={{ padding: 2 }}
          colors={[assets.SLATE_COLOR, assets.BLUE_COLOR]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
        >
          <View
            style={{
              borderRadius: borderRadius ? borderRadius - 2 : 999,
              backgroundColor: "rgba(23,25,36,1)",
              paddingVertical: 4,
              paddingHorizontal: 12,
              alignItems: "stretch",
            }}
          >
            {children}
          </View>
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
};

const STCard = ({ children }) => {
  return (
    <LinearGradient
      style={[{ padding: 8, borderRadius: 16 }, STStyles.shadow]}
      colors={["rgba(125,84,124,1)", "rgba(132,156,215,1)"]}
      start={{ x: 0, y: 0.5 }}
      end={{ x: 1, y: 0.5 }}
    >
      {children}
    </LinearGradient>
  );
};

const TouchableImage = ({ onPress, url, size, style, icon }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress();
      }}
      style={style}
    >
      <View
        style={{
          backgroundColor: assets.GRAY_COLOR,
          height: size,
          width: size,
          borderRadius: size / 2,
          alignSelf: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {url == "" ? (
          <MaterialCommunityIcons
            style={{
              color: "white",
              fontSize: size * 0.7,
              textAlign: "center",
            }}
            name={"account"}
          />
        ) : (
          <CachedImage
            source={{ uri: url }}
            style={{ width: size, height: size, borderRadius: size / 2 }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const STInput = ({
  placeholder,
  value,
  keyboardType,
  onChangeText,
  autoCapitalize,
  autoCorrect,
  secureTextEntry,
  style,
  containerStyle,
  multiline,
}) => {
  return (
    <View style={containerStyle}>
      <TextInput
        placeholder={placeholder}
        value={value}
        keyboardType={keyboardType}
        onChangeText={onChangeText}
        autoCapitalize={autoCapitalize}
        autoCorrect={autoCorrect}
        secureTextEntry={secureTextEntry}
        style={[STStyles.TextInput, { ...style }]}
        placeholderTextColor="gray"
        multiline={multiline}
      ></TextInput>
      <LinearGradient
        style={{ height: 2, marginBottom: 4 }}
        colors={[assets.SLATE_COLOR, assets.BLUE_COLOR]}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
      />
    </View>
  );
};

const ProfileImage = ({ url, firstName, lastName, notification, width }) => {
  let name = "";
  if (firstName && firstName.length > 0) {
    name += firstName[0];
  }
  if (lastName && lastName.length > 0) {
    name += lastName[0];
  }
  return (
    <View>
      <LinearGradient
        style={[
          {
            height: width ? width : assets.PROFILE_IMG_HEIGHT,
            width: width ? width : assets.PROFILE_IMG_HEIGHT,
            borderRadius: 8,
            alignSelf: "center",
            alignContent: "center",
            justifyContent: "center",
          },
          STStyles.shadow,
        ]}
        colors={["rgba(125,84,124,1)", "rgba(132,156,215,1)"]}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
      >
        {url == "" || url === undefined ? (
          <Text style={STStyles.h4}>{name}</Text>
        ) : (
          <CachedImage
            source={{ uri: url }}
            style={{
              width: width ? width : assets.PROFILE_IMG_HEIGHT,
              height: width ? width : assets.PROFILE_IMG_HEIGHT,
              borderRadius: 8,
              alignSelf: "center",
            }}
          />
        )}
      </LinearGradient>
      {notification ? (
        <View
          style={[
            {
              backgroundColor: assets.DARK_COLOR,
              height: 20,
              width: 20,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: assets.RED_COLOR,
              position: "absolute",
              top: -5,
              right: -5,
              alignItems: "center",
              justifyContent: "center",
            },
            STStyles.shadow,
          ]}
        >
          <MaterialCommunityIcons
            style={{
              color: assets.RED_COLOR,
              fontSize: 16,
              textAlign: "center",
            }}
            name={"exclamation"}
          />
        </View>
      ) : null}
    </View>
  );
};

const STStyles = StyleSheet.create({
  title: {
    color: assets.RED_COLOR,
    fontWeight: "900",
    fontSize: assets.FONT_SIZE_TITLE,
    textAlign: "center",
  },
  h1: {
    color: "white",
    fontWeight: "900",
    fontSize: assets.FONT_SIZE_H1,
    textAlign: "left",
  },
  h2: {
    color: "white",
    fontWeight: "900",
    fontSize: assets.FONT_SIZE_H2,
  },
  h3: {
    color: "white",
    fontWeight: "700",
    fontSize: assets.FONT_SIZE_H3,
  },
  h4: {
    color: "white",
    fontWeight: "700",
    fontSize: assets.FONT_SIZE_H4,
    textAlign: "center",
  },
  p: {
    color: "white",
    fontSize: assets.FONT_SIZE_P,
  },
  icon: {
    color: "white",
    fontSize: assets.FONT_SIZE_ICON,
    marginHorizontal: 16,
  },
  button: {
    color: "white",
    fontSize: assets.FONT_SIZE_BUTTON,
    textAlign: "center",
  },
  TextInput: {
    fontSize: assets.FONT_SIZE_H2,
    color: "white",
    textAlign: "center",
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 6,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8.65,

    elevation: 16,
  },
  Segment: {
    backgroundColor: assets.GRADIENT_COLOR_1,
    marginVertical: 5,
  },
});

export {
  assets,
  STButton,
  STButtonContent,
  TouchableImage,
  STCard,
  STInput,
  ProfileImage,
  STStyles,
};
