import React from "react";
import Firebase, { AuthProvider } from "./src/components/firebase";
import { createAppContainer, createSwitchNavigator } from "react-navigation";
import { Provider as GameProvider } from "./src/components/firebase/dataContext/GameContext";

import PlayScreen from "./src/home/PlayScreen";
import InitialScreen from "./src/InitialScreen";

import { createStackNavigator } from "react-navigation-stack";

import * as Linking from "expo-linking";
import GodScreen from "./src/home/GodScreen";

const mainFlow = createStackNavigator({
  Play: {
    screen: PlayScreen,
    path: "player/:gameCode?/:playerCodeParam?",
    navigationOptions: {
      title: "Home",
      headerShown: false,
    },
  },
  God: {
    screen: GodScreen,
    path: "godmode/:gameCode?",
    navigationOptions: {
      title: "Home",
      headerShown: false,
    },
  },
});

const switchNavigator = createSwitchNavigator(
  {
    Initial: {
      screen: InitialScreen,
      navigationOptions: {
        headerShown: false,
      },
    },
    Home: {
      screen: mainFlow,
      path: "play",
    },
  },
  {
    initialRouteName: "Initial",
    headerMode: "screen",
  }
);

const App = createAppContainer(switchNavigator);

const prefix = Linking.createURL("/");

export default () => {
  return (
    <AuthProvider value={Firebase}>
      <GameProvider>
        <App uriPrefix={prefix} />
      </GameProvider>
    </AuthProvider>
  );
};
