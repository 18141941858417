import React, { createContext } from "react";

const AuthContext = createContext({});

export const AuthProvider = AuthContext.Provider;

export const AuthConsumer = AuthContext.Consumer;

export const withFirebaseHOC = (Component) => (props) =>
  (
    <AuthConsumer>
      {(state) => <Component {...props} firebase={state} />}
    </AuthConsumer>
  );
