import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "./firebaseConfig";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const Firebase = {
  // auth
  loginWithEmail: (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  },
  forgotPassword: (email) => {
    console.log("him");
    return firebase.auth().sendPasswordResetEmail(email);
  },
  signOut: () => {
    return firebase.auth().signOut();
  },
  checkUserAuth: (user) => {
    return firebase.auth().onAuthStateChanged(user);
  },

  // firestore
  createNewUser: ({ email, firstName, lastName, uid }) => {
    console.log("Signing up in firestore");
    return firebase
      .firestore()
      .collection("users")
      .doc(`${uid}`)
      .set({ email, firstName, lastName });
  },
};

export default Firebase;
